import serviceAxios from "../index";
export const getbannerimg=(params)=>{
    return serviceAxios({
        method:'get',
        url:'/official/portalSlide/list',
        params,
    })
}
export const getbannerlist=(params)=>{
    return serviceAxios({
        method:'get',
        url:'/official/portalBar/list',
        params,
    })
}
export const getbannerdetail=(params)=>{
    return serviceAxios({
        method:'get',
        url:'/official/portalBar/detail',
        params,
    })
}
export const getarticledetail=(params)=>{
    return serviceAxios({
        method:'get',
        url:'/official/portalArticle/detail',
        params,
    })
}
// export const getbanner=(params)=>{
//     return serviceAxios({
//         method:'get',
//         url:'/official/portalSlide/list',
//         params,
//     })
// }